import React from "react";
import Timer from "./Timer";
import ChooseLanguage from "./ChooseLanguage";
import Modal from "./Modal";
import { openModal } from "../redux/LanguageSlice";
import icon from "../asset/images/icon.svg";
import menu from "../asset/images/menu.svg";
import DayNightToggle from "react-day-and-night-toggle";
import { useDispatch, useSelector } from "react-redux";
import { toggleSideBar } from "../redux/SideBarSlice";
import SubmitConfirmation from "./SubmitConfirmation";
import SubmitConfirmationModal from "./SubmitConfirmationModal";
import { toggleMode } from "../redux/DarkModeSlice";

const Head = ({ isResult }) => {
  const dispatch = useDispatch();
  const openMode = () => {
    dispatch(openModal());
  };
  const toggleSide = () => {
    dispatch(toggleSideBar());
  };
  const testQuestionListData = useSelector(
    (state) => state.testQuestionList?.testQues
  );
  const title = useSelector(
    (state) => state.testQuestionList?.testQues.testTitle
  );
  const isDarkMode = useSelector((state) => state.darkMode.isDarkMode);

  return (
    <div className={`header  ${isDarkMode ? "dark" : "light"}`}>
      <div>
        {!isResult && (
          <div className={` mx-2`}>
            <Timer
              minutes={
                testQuestionListData?.durMins
                  ? testQuestionListData?.durMins
                  : 60
              }
            />
          </div>
        )}
        {/* <p className=" text-[#8696A0] select-none ml-1">
         {title}
         </p> */}
      </div>
      <div className="flex mx-2 select-none items-center">
        <DayNightToggle
          className="mx-4"
          onChange={() => dispatch(toggleMode())}
          checked={isDarkMode}
        />
        <img
          src={icon}
          className={`m-2 ${!isDarkMode && "filter brightness-0"}`}
          alt=""
          onClick={() => {
            openMode();
          }}
        />
        <img
          src={menu}
          className={`m-2 ${!isDarkMode && "filter brightness-0"} select-none`}
          alt=""
          onClick={() => {
            toggleSide();
          }}
        />
      </div>
      <Modal>
        <>
          <ChooseLanguage />
        </>
      </Modal>

      <SubmitConfirmationModal>
        <>
          <SubmitConfirmation
            lastQuestion={false}
            message={"Are you sure you want to submit the test"}
          />
        </>
      </SubmitConfirmationModal>
    </div>
  );
};

export default Head;
