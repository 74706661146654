import React from "react";
import arrow from "../asset/images/arrow-right.svg";
import icon from "../asset/images/icon.svg";
import { openModal } from "../redux/LanguageSlice";
import { useDispatch, useSelector } from "react-redux";
import ChooseLanguage from "./ChooseLanguage";
import DayNightToggle from "react-day-and-night-toggle";
import Modal from "./Modal";
import { toggleMode } from "../redux/DarkModeSlice";

const Header = () => {
  const dispatch = useDispatch();
  const openMode = () => {
    dispatch(openModal());
  };
  const isDarkMode = useSelector((state) => state.darkMode.isDarkMode);

  return (
    <div
      className={`header ${
        !isDarkMode ? "bg-[#139AAA]" : "bg-[#202c33]"
      } text-white`}
    >
      <div className="flex  ">
        <div className="hidden">
          <img className="arrow-right" src={arrow} alt="" />
          <span className="heading">Test - NUM</span>
        </div>
      </div>

      <div className="flex gap-2 items-center">
        <DayNightToggle
          className="mx-4"
          onChange={() => dispatch(toggleMode())}
          checked={isDarkMode}
        />
        <img
          src={icon}
          className=" m-2"
          alt=""
          onClick={() => {
            openMode();
          }}
        />
      </div>

      <Modal>
        <>
          <ChooseLanguage />
        </>
      </Modal>
    </div>
  );
};

export default Header;
