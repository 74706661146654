import { createSlice } from "@reduxjs/toolkit";

const TimerSlice = createSlice({
  name: "timer",
  initialState: {
    time: {
      hours: 0,
      minutes: 1,
      seconds: 0,
    },
  },
  reducers: {
    countdown: function (state, action) {
      state.time.hours =
        state.time.minutes === 0 && state.time.seconds === 0
          ? state.time.hours === 0
            ? 0
            : state.time.hours - 1
          : state.time.hours;
      state.time.minutes =
        state.time.seconds === 0
          ? state.time.minutes === 0
            ? 59
            : state.time.minutes - 1
          : state.time.minutes;
      state.time.seconds =
        state.time.seconds === 0 ? 59 : state.time.seconds - 1;
    },
    setTimer: function (state, action) {
      const { hr, min, sec } = action.payload;
      state.time.seconds = sec;
      state.time.minutes = min;
      state.time.hours = hr;
    },
  },
});

export default TimerSlice.reducer;
export const { countdown, setTimer } = TimerSlice.actions;
