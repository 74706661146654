import { createSlice } from "@reduxjs/toolkit";

const ReportModalSlice = createSlice({
  name: "instructions",
  initialState: {
    instructions: "",
  },
  reducers: {
    setInstructions: (state, action) => {
      state.instructions = action.payload;
    },
  },
});

export default ReportModalSlice.reducer;
export const { setInstructions } = ReportModalSlice.actions;
