import React, { useEffect } from "react";
import QuestionDetails from "./QuestionDetails";
import { useDispatch, useSelector } from "react-redux";
import parse from "html-react-parser";
import tick from "../asset/images/tick.svg";
import wrong from "../asset/images/wrong.png";
import {
  setCurrentQuestion,
  setCurrentQuestionIndex,
  setCurrentTopicIndex,
  setCurrentTopicLength
} from "../redux/TestQuestionListSlice";
import { urls } from "../data/data";
import { apiRequestGet } from "../data/Api";
import { setQuestionDetail } from "../redux/QuestionDetailSlice";
import {
  addAnswer,
  clearCheckedOptions,
  clearResponse,
  setCheckedOptions,
  setCurrentAnswer,
  setCurrentAnswerChangeFalse,
  setCurrentOption,
} from "../redux/AnswerSlice";
import ReportQuestion from "./ReportQuestion";
import ReportModal from "./ReportModal";
import { openModal } from "../redux/SubmitConfirmationSlice";
import { toast } from "react-toastify";

const Question = ({ isResult }) => {
  const questDetail = useSelector((state) => state.questionDetail?.questDetail);
  const language = useSelector((state) => state.modal.language);

  const testQuestionListData = useSelector(
    (state) => state.testQuestionList?.testQues
  );
  const testType = useSelector(
    (state) => state.testAuthenticate.test?.testType
  );

  const currentTopicIndex = useSelector(
    (state) => state.testQuestionList?.currentTopicIndex
  );
  const currentAnswerChange = useSelector(
    (state) => state.answers?.currentAnswerChange
  );
  const totalTopicLength = useSelector(
    (state) => state.testQuestionList?.totalTopicLength
  );
  const currentTopicLength = useSelector(
    (state) => state.testQuestionList?.currentTopicLength
  );
  const currentQuestionIndex = useSelector(
    (state) => state.testQuestionList?.currentQuestionIndex
  );

  const markedAnswers = useSelector((state) => state.answers?.answers);
  const currentOption = useSelector((state) => state.answers?.optionSelected);
  const currentChecked = useSelector((state) => state.answers?.checkedOptions);
  const currentAnswer = useSelector((state) => state.answers?.currentAnswer);
  const answers = useSelector((state) => state.answers?.answers);

  const dispatch = useDispatch();
  const nextQuestion = () => {
    dispatch(
      setCurrentQuestion(
        testQuestionListData.tpcQues?.[currentTopicIndex].quesList?.[
          currentQuestionIndex + 1
        ].id
      )
    );
    getQuestionDetails(
      testQuestionListData.tpcQues?.[currentTopicIndex].quesList?.[
        currentQuestionIndex + 1
      ].id
    );
    dispatch(setCurrentQuestionIndex(currentQuestionIndex + 1));
  };
  const prevQuestion = () => {
    dispatch(
      setCurrentQuestion(
        testQuestionListData.tpcQues?.[currentTopicIndex].quesList?.[
          currentQuestionIndex - 1
        ].id
      )
    );
    getQuestionDetails(
      testQuestionListData.tpcQues?.[currentTopicIndex].quesList?.[
        currentQuestionIndex - 1
      ].id
    );
    dispatch(setCurrentQuestionIndex(currentQuestionIndex - 1));
  };
  const nextTopic = () => {
    getQuestionDetails(
      testQuestionListData.tpcQues?.[currentTopicIndex + 1].quesList?.[0].id
    );
    dispatch(setCurrentQuestionIndex(0));
    dispatch(setCurrentTopicIndex(currentTopicIndex + 1));
    dispatch(
      setCurrentTopicLength(
        testQuestionListData.tpcQues?.[currentTopicIndex + 1].quesList?.length
      )
    );
  };
  const prevTopic = () => {
    getQuestionDetails(
      testQuestionListData.tpcQues?.[currentTopicIndex - 1].quesList?.[0].id
    );
    dispatch(setCurrentQuestionIndex(0));
    dispatch(setCurrentTopicIndex(currentTopicIndex - 1));
  };

  const markQuestion = (key) => {
    const myMap = new Map();
    let obj;
    if (questDetail.type == "scq") {
      obj = {
        quesId: questDetail?.quesId,
        validOpt: currentOption,
        isMarked: 1,
      };
    } else if (questDetail.type == "mcq") {
      obj = {
        quesId: questDetail?.quesId,
        validOpt: currentChecked.join(","),
        isMarked: 1,
      };
    } else if (questDetail.type == "nq") {
      obj = {
        quesId: questDetail?.quesId,
        validOpt: currentAnswer,
        isMarked: 1,
      };
    }
    myMap.set(questDetail?.quesId, obj);
    const object = Object.fromEntries(myMap);
    dispatch(addAnswer(object));
  };

  const saveQuestion = () => {
    const myMap = new Map();
    let obj;
    if (questDetail.type == "scq") {
      obj = {
        quesId: questDetail?.quesId,
        validOpt: currentOption,
        isMarked: 0,
      };
    } else if (questDetail.type == "mcq") {
      obj = {
        quesId: questDetail?.quesId,
        validOpt: currentChecked.join(","),
        isMarked: 0,
      };
    } else if (questDetail.type == "nq") {
      obj = {
        quesId: questDetail?.quesId,
        validOpt: currentAnswer,
        isMarked: 0,
      };
    }
    myMap.set(questDetail?.quesId, obj);
    const object = Object.fromEntries(myMap);
    dispatch(addAnswer(object));
  };

  const lastQuestion = () => {
    dispatch(
      openModal({
        isFull: true,
        message: "Are you sure you want to submit the test",
      })
    );
  };

  const testId = useSelector((state) => state.testConfig.testId);
  const markSaveAndNext = (isMarked) => {
    console.log(currentQuestionIndex + 1, currentTopicLength)
    if (currentQuestionIndex + 1 < currentTopicLength) {
      isMarked ? markQuestion() : saveQuestion();
      nextQuestion();
    } else if (currentTopicIndex + 1 < totalTopicLength) {
      isMarked ? markQuestion() : saveQuestion();
      nextTopic();
    } else {
      isMarked ? markQuestion() : saveQuestion();
      lastQuestion();
    }
    dispatch(setCurrentOption(""));
    dispatch(clearCheckedOptions());
    dispatch(setCurrentAnswer(""));
    dispatch(setCurrentAnswerChangeFalse());
  };

  const prevQues = () => {
    if (currentQuestionIndex - 1 >= 0) {
      prevQuestion();
    } else if (currentTopicIndex - 1 >= 0) {
      prevTopic();
    }
    dispatch(setCurrentOption(""));
    dispatch(clearCheckedOptions());
    dispatch(setCurrentAnswer(""));
    dispatch(setCurrentAnswerChangeFalse());
  };
  const nextQues = () => {
    if (currentQuestionIndex + 1 < currentTopicLength) {
      nextQuestion();
    } else if (currentTopicIndex + 1 < totalTopicLength) {
      nextTopic();
    }
    dispatch(setCurrentOption(""));
    dispatch(clearCheckedOptions());
    dispatch(setCurrentAnswer(""));
    dispatch(setCurrentAnswerChangeFalse());
  };

  useEffect(() => {
    if (
      currentQuestionIndex + 1 == currentTopicLength &&
      currentTopicIndex + 1 == totalTopicLength
    ) {
      toast.info("This is last question");
    }
  }, [currentQuestionIndex, currentTopicIndex]);

  let forResult = "";
  if (isResult)
    forResult = "result";
  const getQuestionDetails = (questId) => {
    fetch(
      `${urls.questionDetail}${questId}?${new URLSearchParams({
        testType,
        testId,
        for: forResult,
      })}`,
      apiRequestGet
    )
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        dispatch(setQuestionDetail(res.data));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const sameOptionMarked = (option) => {
    const quesId = questDetail?.quesId;
    return (
      markedAnswers?.[quesId] != undefined &&
      markedAnswers?.[quesId]?.validOpt?.includes(option)
    );
  };
  const isDarkMode = useSelector((state) => state.darkMode.isDarkMode);

  return (
    <div className=" text-left mx-4 select-none">
      <QuestionDetails />
      {questDetail?.ques?.[language]
        ? parse(questDetail?.ques?.[language])
        : ""}
      {questDetail.type == "scq"
        ? questDetail?.opt?.map((item, index) => {
            return (
              <div
                className={`
             bg-transparent
              ${
                sameOptionMarked(item?.opt) && isDarkMode
                  ? "darkOption"
                  : sameOptionMarked(item?.opt) && !isDarkMode
                  ? "lightOption"
                  : ""
              }
              flex justify-between select-none   border border-solid border-[#8696A0] px-2 py-4 my-1 rounded-md`}
                key={index}
              >
                <div className=" flex ">
                  <p className="uppercase mx-4">{item.opt} </p>
                  {item[language] ? parse(item[language]) : ""}
                </div>
                {!isResult && (
                  <input
                    type="radio"
                    className="radio"
                    name="option"
                    value={item.opt}
                    checked={currentOption == item.opt}
                    onChange={(e) => {
                      dispatch(setCurrentOption(e.target.value));
                    }}
                  />
                )}

                {isResult &&
                  (sameOptionMarked(item?.opt) &&
                  !questDetail.vldOpt.split(",").includes(item.opt) ? (
                    <img
                      src={wrong}
                      className="bg-[#C30232] rounded-full"
                      alt=""
                    />
                  ) : (
                    questDetail.vldOpt.split(",").includes(item.opt) && (
                      <img src={tick} className="tickSize" alt="" />
                    )
                  ))}
              </div>
            );
          })
        : questDetail.type == "mcq"
        ? questDetail?.opt?.map((item, index) => {
            return (
              <div
                className={`${
                  sameOptionMarked(item?.opt) && isDarkMode
                    ? "darkOption"
                    : "lightOption"
                } 
               
                flex justify-between select-none   border border-solid border-[#8696A0] px-2 py-4 my-1 rounded-md`}
                key={index}
              >
                <div className=" flex ">
                  <p className="uppercase mx-4">{item.opt} </p>
                  {item?.[language] && parse(item?.[language])}
                </div>
                {!isResult && (
                  <input
                    type="checkbox"
                    className="radio"
                    name="option"
                    value={item.opt}
                    checked={currentChecked.includes(item.opt) ? true : false}
                    onChange={(e) => {
                      const { checked, value } = e.target;
                      const obj = { checked, value };
                      dispatch(setCheckedOptions(obj));
                    }}
                  />
                )}
                {isResult &&
                  (sameOptionMarked(item?.opt) &&
                  !questDetail.vldOpt.split(",").includes(item.opt) ? (
                    <img
                      src={wrong}
                      className="bg-[#C30232] rounded-full"
                      alt=""
                    />
                  ) : (
                    questDetail.vldOpt.split(",").includes(item.opt) && (
                      <img src={tick} className="tickSize" alt="" />
                    )
                  ))}
                {/* {isResult &&
                  questDetail.vldOpt.split(",").includes(item.opt) && (
                    <img src={tick} className="tickSize" alt="" />
                  )} */}
              </div>
            );
          })
        : questDetail.type == "nq" && (
            <div className="flex justify-center align-middle my-2 bg-white items-center">
              <input
                type="text"
                className="inp m-0 bg-transparent"
                value={
                  !currentAnswerChange
                    ? answers?.[questDetail?.quesId]?.validOpt
                      ? answers?.[questDetail?.quesId]?.validOpt
                      : questDetail?.uselOpt
                    : currentAnswer
                }
                placeholder="Enter the answer"
                onChange={(e) => {
                  dispatch(setCurrentAnswer(e.target.value));
                }}
              />
              <div>
                {isResult &&
                  (answers[questDetail?.quesId]?.validOpt &&
                  answers[questDetail?.quesId]?.validOpt !=
                    questDetail.vldOpt ? (
                    <img
                      src={wrong}
                      className="bg-[#C30232] rounded-full"
                      alt=""
                    />
                  ) : answers[questDetail?.quesId]?.validOpt &&
                    answers[questDetail?.quesId]?.validOpt !=
                      questDetail.vldOpt ? (
                    <img src={tick} className="tickSize" alt="" />
                  ) : (
                    ""
                  ))}
              </div>
            </div>
          )}
      {/* <div className="h-40"> */}
      {!isResult && (
        <div>
          <div className="flex justify-center gap-4  py-8 ">
            <button
              className={`${
                isDarkMode ? "text-white" : "text-black"
              } border border-solid border-[#8696A0] rounded-md px-4 py-2`}
              onClick={() => {
                dispatch(setCurrentOption(""));
                dispatch(clearResponse(questDetail?.quesId));
                dispatch(setCurrentAnswerChangeFalse());
              }}
            >
              Clear Response
            </button>
          </div>
          {/* </div> */}

          <div className="flex justify-center gap-4 mb-4">
            <button
              className={` ${
                isDarkMode ? "text-white" : "text-black"
              } border w-full  border-solid border-[#8696A0] rounded-md px-4 py-2`}
              onClick={() => {
                markSaveAndNext(1);
              }}
            >
              Mark & Next
            </button>
            <button
              className="btn px-4 rounded-md py-2 w-full "
              onClick={() => {
                markSaveAndNext(0);
              }}
            >
              Save & Next
            </button>
          </div>
        </div>
      )}
      {isResult && questDetail?.soln?.[language] && (
        <div>
          <div className="flex justify-center gap-4 mt-4 mb-4">
            <button
              className={` ${
                isDarkMode ? "text-black bg-white" : "text-black bg-black"
              } border w-full  border-solid border-[#8696A0] rounded-md px-4 py-2`}
              onClick={() => {
                prevQues();
              }}
            >
              Prev
            </button>
            <button
              className="btn px-4 rounded-md py-2 w-full "
              onClick={() => {
                nextQues();
              }}
            >
              Next
            </button>
          </div>
          <div className="text-black bg-[#5cb85c] p-4 rounded-md  w-full  my-4">
            {parse(questDetail?.soln?.[language])}
          </div>
        </div>
      )}
      <ReportModal>
        <>
          <ReportQuestion />
        </>
      </ReportModal>
    </div>
  );
};

export default Question;
