import { createSlice } from "@reduxjs/toolkit";

const ReportedQuestionsSlice = createSlice({
  name: "reportedQuestions",
  initialState: {
    reportedQuestions: {},
  },
  reducers: {
    reportQuestion: function (state, action) {
      Object.assign(state.reportedQuestions, action.payload);
    },
  },
});

export default ReportedQuestionsSlice.reducer;
export const { reportQuestion } = ReportedQuestionsSlice.actions;
