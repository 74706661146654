import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import arrdown from "../asset/images/arrow-down.svg";
import {
  setCurrentQuestion,
  setCurrentQuestionIndex,
  setCurrentTopicIndex,
} from "../redux/TestQuestionListSlice";
import { setQuestionDetail } from "../redux/QuestionDetailSlice";
import { urls } from "../data/data";
import { apiRequestGet } from "../data/Api";
import { closeSideBar } from "../redux/SideBarSlice";
import {
  clearCheckedOptions,
  setCurrentAnswer,
  setCurrentAnswerChangeFalse,
  setCurrentOption,
} from "../redux/AnswerSlice";
import { setLanguage } from "../redux/LanguageSlice";

function ReviewAnswers({ isResult }) {
  const testQuestionListData = useSelector(
    (state) => state.testQuestionList?.testQues
  );
  const [activeIndex, setActiveIndex] = useState(0);
  const answers = useSelector((state) => state.answers?.answers);

  const dispatch = useDispatch();
  const testType = useSelector(
    (state) => state.testAuthenticate.test?.testType
  );
  const testId = useSelector((state) => state.testConfig.testId);
  const language = useSelector((state) => state.modal.language);

  const check = (validOpt, correctOpt) => {
    if (validOpt != undefined && validOpt.length) {
      let validOptArr = validOpt.split(","),
        correctOptArr = correctOpt.split(",");
      if (validOptArr === correctOptArr) {
        return "bg-[#2CB370]";
      } else if (
        validOptArr.filter((e) => correctOpt.includes(e)).length ==
        validOptArr.length
      ) {
        return "bg-[#2CB370]";
      } else {
        return "bg-[#C30232]";
      }
    }
    return "";
  };

  const getQuestionDetails = (questId) => {
    fetch(
      `${urls.questionDetail}${questId}?${new URLSearchParams({
        testType,
        testId,
        for: isResult && "result",
      })}`,
      apiRequestGet
    )
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        const quesLang = Object.keys(res.data?.ques).filter(
          (key) => res.data?.ques[key] !== ""
        );
        dispatch(
          setLanguage(quesLang.includes(language) ? language : quesLang?.[0])
        );
        dispatch(setQuestionDetail(res.data));
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const isDarkMode = useSelector((state) => state.darkMode.isDarkMode);

  return (
    <div className="overflow-y-auto  m-4  ">
      {testQuestionListData?.tpcQues?.map((item, index) => {
        return (
          <div
            key={index}
            className={`${
              isDarkMode ? "bg-[#202C33]" : "bg-white"
            }  my-3 border-[#5B5B5B] border border-solid rounded-lg `}
          >
            <div
              onClick={() => {
                setActiveIndex(index);
              }}
              className={`${
                isDarkMode ? "dark" : "light"
              } border-[#5B5B5B] border-b rounded-sm border-solid flex rounded-t-lg justify-between p-2`}
            >
              <p className=" ">{item.topicName}</p>
              {activeIndex !== index ? (
                <button>
                  <img src={arrdown} alt="" />
                </button>
              ) : (
                ""
              )}
            </div>
            {activeIndex == index && (
              <div className="grid gap-2  grid-cols-5 m-2">
                {item.quesList.map((ques, quesIndex) => {
                  return (
                    <div key={quesIndex} className="m-1 flex flex-col">
                      <p
                        onClick={() => {
                          dispatch(setCurrentQuestion(ques.id));
                          dispatch(setCurrentTopicIndex(index));
                          dispatch(setCurrentQuestionIndex(quesIndex));
                          getQuestionDetails(ques.id);
                          dispatch(closeSideBar());
                          dispatch(setCurrentOption(""));
                          dispatch(clearCheckedOptions());
                          dispatch(setCurrentAnswer(""));
                          dispatch(setCurrentAnswerChangeFalse());
                        }}
                        className={`${
                          !isResult
                            ? answers?.[ques.id]?.validOpt &&
                              answers?.[ques.id]?.isMarked == 0
                              ? "bg-[#2CB370]"
                              : answers?.[ques.id]?.isMarked == 1
                              ? "bg-[#FFA726]"
                              : answers?.[ques.id]
                              ? "bg-[#D9D9D9]"
                              : ""
                            : check(
                                answers?.[ques.id]?.validOpt,
                                answers?.[ques.id]?.correctOpt
                              )
                        }  flex justify-center quess-center rounded-md  border-[#5B5B5B] border border-solid px-2 py-1  `}
                      >
                        {quesIndex + 1}
                      </p>
                      <p className="uppercase">{ques.type}</p>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
}

export default ReviewAnswers;
