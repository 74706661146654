import React, { useEffect, useState } from "react";
import { urls } from "../data/data";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Header from "./Header";
import { apiRequestGet } from "../data/Api";
import { setTestDetail } from "../redux/TestAuthenticateSlice";
import parse from "html-react-parser";
import { setInstructions } from "../redux/InstructionsSlice";

const Instructions = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const testInstruction = useSelector(
    (state) => state.instructions.instructions
  );
  const testId = useSelector((state) => state.testConfig.testId);
  const tk = useSelector((state) => state.testConfig.tk);
  useEffect(() => {
    if (testInstruction) return;
    const queryParams = {
      tk: tk,
    };

    const url = `${urls.test}${testId}?${new URLSearchParams(queryParams)}`;
    fetch(url, apiRequestGet)
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        dispatch(setTestDetail(res?.data));
        fetch(res?.data?.testIns)
          .then((res) => {
            return res.text();
          })
          .then((res) => {
            dispatch(setInstructions(res));
            navigate("/instructions");
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const isDarkMode = useSelector((state) => state.darkMode.isDarkMode);

  return (
    <>
      <Header />
      <div
        className={` ${
          isDarkMode ? "dark" : "light"
        } p-4   -top-5 min-h-[85.8vh] h-full`}
      >
        {/* <div className="text-left text-lg text-white list-disc" dangerouslySetInnerHTML={{ __html: testInstruction }}></div> */}
        <div className="text-left text-lg list-disc mx-4">
          {testInstruction && parse(testInstruction)}
        </div>
        {testInstruction && (
          <div className=" p-4">
            {
              <button
                className="btn px-20 py-3 rounded-md "
                onClick={() => navigate("/test")}
              >
                Agree & Continue
              </button>
            }
          </div>
        )}
      </div>
    </>
  );
};

export default Instructions;
