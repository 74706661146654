import React, { useState, useEffect } from "react";
import pause from "../asset/images/pause.svg";
import { useDispatch, useSelector } from "react-redux";
import { countdown, setTimer } from "../redux/TimerSlice";
import { apiRequestPost } from "../data/Api";
import { urls } from "../data/data";
import { useNavigate } from "react-router-dom";
import { openModal } from "../redux/SubmitConfirmationSlice";

const Timer = ({ hours = 0, minutes, seconds = 0 }) => {
  const [paused, setPaused] = useState(false);
  const dispatch = useDispatch();
  const time = useSelector((state) => state.timer.time);
  const answers = useSelector((state) => state.answers?.answers);

  const navigate = useNavigate();
  const durMin = useSelector(
    (state) => state.testQuestionList.testQues.durMins
  );
  const testId = useSelector((state) => state.testConfig.testId);
  const isLive = useSelector((state) => state.testAuthenticate.test?.isLive);
  const currentTopicIndex = useSelector(
    (state) => state.testQuestionList.currentTopicIndex
  );
  const currentQuestionIndex = useSelector(
    (state) => state.testQuestionList.currentQuestionIndex
  );

  const handleSubmit = (isFull = true) => {
    const mcqSbmt = Object.values(answers).filter((e) => {
      return e.validOpt != "";
    });
    const markedSbmt = Object.values(answers).filter((e) => {
      return e.isMarked === 1;
    });
    const requestBody = {
      mcqSbmt: [...mcqSbmt],
      markedSbmt: [...markedSbmt],
      timeTaken: (durMin - time.minutes)?.toString(),
      submitType: isFull ? "full" : "partial",
      resumeSec: `${currentTopicIndex},${currentQuestionIndex}`,
    };
    fetch(urls.submitQues + `/${testId}`, {
      ...apiRequestPost,
      body: JSON.stringify(requestBody),
    })
      .then((data) => {
        navigate("/submssionSuccess");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    const timer = setInterval(() => {
      if (!paused) {
        if (time.hours === 0 && time.minutes === 0 && time.seconds === 0) {
          clearInterval(timer);
          handleSubmit();
        } else {
          dispatch(countdown());
        }
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [time, paused]);

  const handlePauseToggle = () => {
    setPaused((prevPaused) => !prevPaused);
    dispatch(
      openModal({
        isFull: false,
        message: "Are you sure you want to pause the test",
      })
    );
  };

  return (
    <div>
      <div className="flex">
        {!+isLive && (
          <button
            onClick={() => {
              handlePauseToggle();
            }}
          >
            {paused ? <img src={pause} alt="" /> : <img src={pause} alt="" />}
          </button>
        )}

        <div className="flex flex-col items-baseline ">
          <div className="flex mx-2">
            <div className="text-xl">
              {time.hours?.toString().padStart(2, "0")}
              <p className="text-xs">hour</p>
            </div>{" "}
            :
            <div className="text-xl">
              {time.minutes?.toString().padStart(2, "0")}
              <p className="text-xs">min</p>
            </div>{" "}
            :
            <div className="text-xl">
              {time.seconds?.toString().padStart(2, "0")}
              <p className="text-xs">sec</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Timer;
