import { configureStore } from "@reduxjs/toolkit";
import LanguageSlice from "./LanguageSlice";
import SideBarSlice from "./SideBarSlice";
import TestAuthenticateSlice from "./TestAuthenticateSlice";
import TestQuestionListSlice from "./TestQuestionListSlice";
import QuestionDetailSlice from "./QuestionDetailSlice";
import AnswerSlice from "./AnswerSlice";
import ReportModalSlice from "./ReportModalSlice";
import TimerSlice from "./TimerSlice";
import ReportedQuestionsSlice from "./ReportedQuestionsSlice";
import SubmitConfirmationSlice from "./SubmitConfirmationSlice";
import ResultSlice from "./ResultSlice";
import TestConfigSlice from "./TestConfigSlice";
import InstructionsSlice from "./InstructionsSlice";
import DarkModeSlice from "./DarkModeSlice";

export const store = configureStore({
  reducer: {
    modal: LanguageSlice,
    sidebar: SideBarSlice,
    testAuthenticate: TestAuthenticateSlice,
    testQuestionList: TestQuestionListSlice,
    questionDetail: QuestionDetailSlice,
    answers: AnswerSlice,
    report: ReportModalSlice,
    timer: TimerSlice,
    reportedQuestions: ReportedQuestionsSlice,
    submitConfirmation: SubmitConfirmationSlice,
    result: ResultSlice,
    testConfig: TestConfigSlice,
    instructions: InstructionsSlice,
    darkMode: DarkModeSlice,
  },
});
