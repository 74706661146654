import React from "react";
import close from "../asset/images/close.svg";
import { useDispatch, useSelector } from "react-redux";
import { closeModal } from "../redux/SubmitConfirmationSlice";

const SubmitConfirmationModal = ({ children }) => {
  const isOpen = useSelector((state) => state.submitConfirmation.isOpen);
  const dispatch = useDispatch();
  const closeMode = () => {
    dispatch(closeModal());
  };
  
  if (!isOpen) return "";
  return (
    <div className="h-full z-10 bg-[#000000] w-full bg-opacity-50 top-0 left-0  fixed flex items-center justify-center">
      <div className=" justify-center items-center my-auto flex relative bg-[#202C33] min-wp-[330px] min-h-[200px] m-auto p-4  rounded-md shadow-[0px 4px 12px 0px #00000033] border-[1.5] border-solid border-[#353535] ">
        <button
          className=" absolute top-2 right-2"
          onClick={() => {
            closeMode();
          }}
        >
          <img src={close} alt="" />
        </button>
        {children}
      </div>
    </div>
  );
};

export default SubmitConfirmationModal;
