import { createSlice } from "@reduxjs/toolkit";

const TestQuestionListSlice = createSlice({
  name: "testQuesList",
  initialState: {
    testQues: {},
    currentTopic: "",
    currentTopicIndex: 0,
    currentQuestion: "",
    currentQuestionIndex: 0,
    currentTopicLength: 0,
    totalTopicLength: 0,
  },
  reducers: {
    setQuestionListData: function (state, action) {
      state.testQues = { ...action.payload };
    },
    setCurrentTopic: function (state, action) {
      state.currentTopic = action.payload;
    },
    setCurrentTopicIndex: function (state, action) {
      state.currentTopicIndex = action.payload;
    },
    setCurrentQuestion: function (state, action) {
      state.currentQuestion = action.payload;
    },
    setCurrentQuestionIndex: function (state, action) {
      state.currentQuestionIndex = action.payload;
    },
    setCurrentTopicLength: function (state, action) {
      state.currentTopicLength = action.payload;
    },
    setTotalTopicLength: function (state, action) {
      state.totalTopicLength = action.payload;
    },
  },
});

export default TestQuestionListSlice.reducer;
export const {
  setTotalTopicLength,
  setQuestionListData,
  setCurrentTopic,
  setCurrentQuestion,
  setCurrentQuestionIndex,
  setCurrentTopicLength,
  setCurrentTopicIndex,
} = TestQuestionListSlice.actions;
