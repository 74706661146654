import { createSlice } from "@reduxjs/toolkit";

const ResultSlice = createSlice({
  name: "result",
  initialState: {
    forResult: true,
  },
  reducers: {},
});

export default ResultSlice.reducer;
export const { openModal, closeModal } = ResultSlice.actions;
