import { createSlice } from "@reduxjs/toolkit";

const SideBarSlice = createSlice({
  name: "sidebar",
  initialState: {
    isOpen: false,
  },
  reducers: {
    toggleSideBar: function (state, action) {
      state.isOpen = !state.isOpen;
    },
    closeSideBar: function (state, action) {
      state.isOpen = false;
    },
  },
});

export default SideBarSlice.reducer;
export const { toggleSideBar, closeSideBar } = SideBarSlice.actions;
