import React from "react";
import { closeSideBar, toggleSideBar } from "../redux/SideBarSlice";
import { useDispatch, useSelector } from "react-redux";
import ReviewAnswers from "./ReviewAnswers";
import close from "../asset/images/close.svg";
import { useNavigate } from "react-router-dom";
import { openModal } from "../redux/SubmitConfirmationSlice";

const SideBar = ({ isResult }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const toggleSide = () => {
    dispatch(toggleSideBar());
  };
  const closeSide = () => {
    dispatch(closeSideBar());
  };
  const answers = useSelector((state) => state.answers?.answers);
  const resultData = useSelector(
    (state) => state.testQuestionList?.testQues?.resultData
  );
  const isDarkMode = useSelector((state) => state.darkMode.isDarkMode);

  return (
    <div
      className={`${
        isDarkMode ? "dark" : "light"
      } z-10 h-full w-3/4   fixed top-0 right-0 flex flex-col justify-between 
      shadow-lg`}
    >
      <button
        className=" text-right mx-2 relative mt-2 mb-6 "
        onClick={() => {
          closeSide();
        }}
      >
        <img
          src={close}
          className={`absolute right-1 ${!isDarkMode && "filter brightness-0"}`}
          alt="closeBtn"
        />
      </button>
      {!isResult ? (
        <div className="mx-4">
          <p
            className={`text-left border-b-2 border-dotted ${
              isDarkMode ? "border-white text-white" : "border-black text-black"
            }  py-4`}
          >
            {" "}
            <span className="bg-[#2CB370] mx-2 py-2 px-2">
              {
                Object.values(answers).filter((e) => {
                  return e?.isMarked == 0 && e?.validOpt != "";
                }).length
              }
            </span>
            Attempted
          </p>
          <p
            className={`text-left border-b-2 border-dotted ${
              isDarkMode ? "border-white text-white" : "border-black text-black"
            }  py-4`}
          >
            <span className="bg-[#D9D9D9] mx-2 py-2 px-2">
              {
                Object.values(answers).filter((e) => {
                  return e?.isMarked == 0 && e.validOpt === "";
                })?.length
              }
            </span>
            Unattempted
          </p>
          <p
            className={`text-left border-b-2 border-dotted ${
              isDarkMode ? "border-white text-white" : "border-black text-black"
            }  py-4`}
          >
            {" "}
            <span className="bg-[#FFA726] mx-2 py-2 px-2">
              {
                Object.values(answers).filter((e) => {
                  return e?.isMarked == 1;
                }).length
              }
            </span>
            Marked for review
          </p>
        </div>
      ) : (
        <div className="text-white">
          <p
            className={`text-left border-b-2 border-dotted ${
              isDarkMode ? "border-white text-white" : "border-black text-black"
            }  py-4`}
          >
            {" "}
            <span className="bg-[#2CB370] mx-2 py-2 px-2">
              {resultData?.correctCount}
            </span>
            Correct
          </p>
          <p
            className={`text-left border-b-2 border-dotted ${
              isDarkMode ? "border-white text-white" : "border-black text-black"
            }  py-4`}
          >
            {" "}
            <span className="bg-[#C30232] mx-2 py-2 px-2">
              {resultData?.incorrectCount}
            </span>
            Incorrect
          </p>
          <p
            className={`text-left border-b-2 border-dotted ${
              isDarkMode ? "border-white text-white" : "border-black text-black"
            }  py-4`}
          >
            {" "}
            <span className="bg-[#FFA726] mx-2 py-2 px-2">
              {
                Object.values(answers).filter((e) => {
                  return e?.isMarked == 1;
                }).length
              }
            </span>
            Marked for review
          </p>
          <p
            className={`text-left border-b-2 border-dotted ${
              isDarkMode ? "border-white text-white" : "border-black text-black"
            }  py-4`}
          >
            <span className="bg-[#D9D9D9] mx-2 py-2 px-2">
              {resultData?.unattemptCount}
            </span>
            Unattempted
          </p>
        </div>
      )}
      <ReviewAnswers isResult={isResult} />
      {!isResult && (
        <div className="px-4">
          <button
            className="btn px-4 rounded-md py-2 w-full "
            onClick={() => {
              closeSide();
              dispatch(
                openModal({
                  isFull: true,
                  message: "Are you sure you want to submit the test",
                })
              );
            }}
          >
            Submit
          </button>
        </div>
      )}
    </div>
  );
};

export default SideBar;
