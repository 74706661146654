import { createSlice } from "@reduxjs/toolkit";

const SubmitConfirmationSlice = createSlice({
  name: "submitConfirmation",
  initialState: {
    isOpen: false,
    isFull: true,
    message: "Are you sure you want to submit the test?",
  },
  reducers: {
    openModal: function (state, action) {
      state.isOpen = true;
      state.isFull = action.payload.isFull;
      state.message = action.payload.message;
    },
    closeModal: function (state, action) {
      state.isOpen = false;
    },
  },
});

export default SubmitConfirmationSlice.reducer;
export const { openModal, closeModal } = SubmitConfirmationSlice.actions;
