import { createSlice } from "@reduxjs/toolkit";

const LanguageSlice = createSlice({
  name: "language",
  initialState: {
    language: "eng",
    isOpen: false,
  },
  reducers: {
    setLanguage: function (state, action) {
      state.language = action.payload;
    },
    openModal: function (state, action) {
      state.isOpen = true;
    },
    closeModal: function (state, action) {
      state.isOpen = false;
    },
  },
});

export default LanguageSlice.reducer;
export const { setLanguage, openModal, closeModal } = LanguageSlice.actions;
