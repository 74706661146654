import React from "react";
import Container from "./Container";

const Main = () => {
  return (
    <div className=" ">
      {/* <Header/> */}
      {/* <Header/> */}
      <Container />
    </div>
  );
};

export default Main;
