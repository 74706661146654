import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeModal } from "../redux/SubmitConfirmationSlice";
import { urls } from "../data/data";
import { apiRequestPost } from "../data/Api";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const SubmitConfirmation = ({ message, lastQuestion }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const answers = useSelector((state) => state.answers?.answers);
  const time = useSelector((state) => state.timer.time);

  const durMin = useSelector(
    (state) => state.testQuestionList.testQues.durMins
  );
  const currentTopicIndex = useSelector(
    (state) => state.testQuestionList.currentTopicIndex
  );
  const currentQuestionIndex = useSelector(
    (state) => state.testQuestionList.currentQuestionIndex
  );
  const isFull = useSelector((state) => state.submitConfirmation.isFull);
  const isMessage = useSelector((state) => state.submitConfirmation.message);
  const testId = useSelector((state) => state.testConfig.testId);

  const handleSubmit = () => {
    const mcqSbmt = Object.values(answers).filter((e) => {
      return e.validOpt != "";
    });
    const markedSbmt = Object.values(answers).filter((e) => {
      return e.isMarked === 1;
    });
    const requestBody = {
      mcqSbmt: [...mcqSbmt],
      markedSbmt: [...markedSbmt],
      timeTaken: (durMin - time.minutes).toString(),
      submitType: isFull ? "full" : "partial",
      resumeSec: `${currentTopicIndex},${currentQuestionIndex}`,
    };
    toast.promise(
      fetch(urls.submitQues + `/${testId}`, {
        ...apiRequestPost,
        body: JSON.stringify(requestBody),
      })
        .then((data) => {
          navigate("/submssionSuccess");
        })
        .catch((err) => {
          console.log(err);
        }),
      {
        pending: "Please wait",
        success: "Submission Succesful",
        error: "Some error.. 🤯",
      }
    );
  };

  return (
    <div className="flex flex-col gap-1 py-2  text-white items-center justify-center ">
      <p className="text-xl text-center">{isMessage}</p>
      <div className="flex justify-center gap-4 mt-4">
        <button
          className={`text-white border w-full  border-solid border-[#8696A0] rounded-md px-4 py-2`}
          onClick={() => dispatch(closeModal())}
        >
          Cancel
        </button>
        <button
          className="btn px-4 rounded-md py-2 w-full "
          onClick={() => {
            handleSubmit();
          }}
        >
          Submit
        </button>
      </div>
    </div>
  );
};

export default SubmitConfirmation;
