import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import report from "../asset/images/report.svg";
import { urls } from "../data/data";
import { apiRequestPost } from "../data/Api";
import { useNavigate } from "react-router-dom";
import { reportQuestion } from "../redux/ReportedQuestionsSlice";
import { toast } from "react-toastify";
import { closeModal } from "../redux/ReportModalSlice";

const ReportQuestion = () => {
  const reportCategories = useSelector(
    (state) => state.testQuestionList?.testQues?.mdrCat
  );
  const [catId, setCatId] = useState("");
  const [remarks, setRemarks] = useState("");
  const dispatch = useDispatch();
  const currentQuestion = useSelector(
    (state) => state.testQuestionList?.currentQuestion
  );
  const navigate = useNavigate();
  const testId = useSelector((state) => state.testConfig.testId);
  const reportedQuestions = useSelector(
    (state) => state.reportedQuestions.reportedQuestions
  );
  
  const handleSubmit = () => {
    const requestBody = {
      testId: testId,
      qId: currentQuestion,
      catId: catId,
      reason: remarks,
    };
    toast.promise(
      fetch(urls.reportQuestion, {
        ...apiRequestPost,
        body: JSON.stringify(requestBody),
      }).then(() => {
        dispatch(closeModal());
      }),
      {
        pending: "Please wait",
        success: "Reported Succesful",
        error: "Some error.. 🤯",
      }
    );
    const obj = {
      quesId: currentQuestion,
      catId: catId,
      reason: remarks,
    };
    const myMap = new Map();

    myMap.set(currentQuestion, obj);
    const object = Object.fromEntries(myMap);
    dispatch(reportQuestion(object));
  };
  const isDarkMode = useSelector((state) => state.darkMode.isDarkMode);

  return (
    <div className=" ">
      <p className="text-2xl text-center font-bold">Report Question!</p>
      {reportCategories?.map((item, index) => {
        return (
          <div
            key={item.value}
            className={`${
              isDarkMode
                ? catId == item.value ||
                  reportedQuestions[currentQuestion]?.catId == item.value
                  ? "bg-[#C30232] text-white"
                  : "text-white "
                : catId == item.value ||
                  reportedQuestions[currentQuestion]?.catId == item.value
                ? "bg-[#C30232] text-white"
                : "text-black "
            }  cursor-pointer border border-solid border-white flex justify-between my-1 rounded-md px-4 py-2`}
            onClick={() => {
              setCatId(item.value);
            }}
            value={item.value}
          >
            <p className="p-2 rounded-md">{item.name}</p>
            <img src={report} alt="" />
          </div>
        );
      })}
      {catId === "99" && (
        <textarea
          className="w-full h-28 text-black flex justify-center items-center mx-auto my-2 px-2 py-1"
          onChange={(e) => {
            setRemarks(e.target.value);
          }}
          name=""
          // rows={5}
          // cols={40}
          value={remarks}
          id=""
          placeholder="Please tell more about this issue (at least 7-8 words) for quick resolution    "
        ></textarea>
      )}
      <div>
        {!reportedQuestions.hasOwnProperty(currentQuestion) && (
          <button
            className="btn px-4 rounded-md py-2 w-full "
            onClick={() => {
              handleSubmit();
            }}
          >
            Submit
          </button>
        )}
      </div>
    </div>
  );
};

export default ReportQuestion;
