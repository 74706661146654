import React from "react";
import close from "../asset/images/close.svg";
import { useDispatch, useSelector } from "react-redux";
import { closeModal } from "../redux/LanguageSlice";

const Modal = ({ children }) => {
  const isOpen = useSelector((state) => state.modal.isOpen);
  const dispatch = useDispatch();
  const closeMode = () => {
    dispatch(closeModal());
  };
  const isDarkMode = useSelector((state) => state.darkMode.isDarkMode);
  
  if (!isOpen) return "";
  return (
    <div
      className={`   h-full z-10  w-full bg-opacity-50 top-0 left-0  fixed flex items-center justify-center`}
    >
      <div
        className={`${
          isDarkMode ? "dark" : "light"
        } relative  shadow-lg min-w-[330px] min-h-[275px] m-auto p-4  rounded-md shadow-[0px 4px 12px 0px #00000033] border-[1.5] border-solid border-[#353535] `}
      >
        <button
          className={` ${
            !isDarkMode && "filter brightness-0"
          } absolute top-2 right-2 select-none`}
          onClick={() => {
            closeMode();
          }}
        >
          <img src={close} alt="" />
        </button>
        {children}
      </div>
    </div>
  );
};

export default Modal;
