import { createSlice } from "@reduxjs/toolkit";

const QuestionDetailSlice = createSlice({
  name: "questionDetail",
  initialState: {
    questDetail: {},
  },
  reducers: {
    setQuestionDetail: function (state, action) {
      state.questDetail = { ...action.payload };
    },
  },
});

export default QuestionDetailSlice.reducer;
export const { setQuestionDetail } = QuestionDetailSlice.actions;
