import { createSlice } from "@reduxjs/toolkit";

const AnswerSlice = createSlice({
  name: "answers",
  initialState: {
    answers: {},
    optionSelected: "",
    currentAnswer: "",
    currentAnswerChange: false,
    checkedOptions: [],
  },
  reducers: {
    addAnswer: function (state, action) {
      Object.assign(state.answers, action.payload);
    },
    addAttemptedAnswer: function (state, action) {
      state.answers = { ...action.payload };
    },
    clearResponse: function (state, action) {
      delete state.answers[action.payload];
    },
    setCurrentOption: function (state, action) {
      state.optionSelected = action.payload;
    },
    setCheckedOptions: function (state, action) {
      const { value, checked } = action.payload;
      if (checked) {
        state.checkedOptions = [...state.checkedOptions, value];
      } else {
        state.checkedOptions = state.checkedOptions.filter((e) => {
          return e !== value;
        });
      }
    },
    clearCheckedOptions: function (state, action) {
      state.checkedOptions = [];
    },
    setCurrentAnswer: function (state, action) {
      state.currentAnswer = action.payload;
      state.currentAnswerChange = true;
    },
    setCurrentAnswerChangeFalse: function (state, action) {
      state.currentAnswerChange = false;
    },
  },
});

export default AnswerSlice.reducer;
export const {
  addAnswer,
  clearResponse,
  setCurrentOption,
  setCheckedOptions,
  setCurrentAnswer,
  clearCheckedOptions,
  setCurrentAnswerChangeFalse,
  addAttemptedAnswer,
} = AnswerSlice.actions;
