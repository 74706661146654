import React, { useEffect } from "react";
import languageIcon from "../asset/images/languageIcon.svg";
import { useDispatch, useSelector } from "react-redux";
import { setLanguage } from "../redux/LanguageSlice";

const ChooseLanguage = () => {
  const dispatch = useDispatch();
  const setLang = (e) => {
    dispatch(setLanguage(e.target.id));
  };
  const language = useSelector((state) => state.modal.language);
  const testLanguages = useSelector((state) => state.testAuthenticate.test);
  const ques = useSelector((state) => state.questionDetail?.questDetail?.ques);
  const isDarkMode = useSelector((state) => state.darkMode.isDarkMode);

  return (
    <div className="  flex py-2 items-center justify-center flex-col ">
      <img src={languageIcon} alt="" />
      <p className="">Choose Language</p>

      {ques ? (
        Object.keys(ques)
          .filter((key) => ques[key] !== "")
          .map((item, index) => (
            <div
              key={index}
              className={` flex justify-between px-4  w-full mx-4 my-2 py-4 border border-[solid] rounded-md ${
                isDarkMode ? "border-white" : "border-black"
              } `}
            >
              {}
              {
                <>
                  <label>{item.toUpperCase()}</label>
                  <input
                    type="radio"
                    className="radio"
                    name="language"
                    value={item}
                    id={item}
                    onChange={(e) => setLang(e)}
                    checked={item == language ? true : false}
                  />
                </>
              }
            </div>
          ))
      ) : testLanguages?.testlang ? (
        testLanguages?.testlang?.map((item, index) => {
          return (
            <div
              key={index}
              className={` flex justify-between px-4  w-full mx-4 my-2 py-4 border border-[solid] rounded-md ${
                isDarkMode ? "border-white" : "border-black"
              } `}
            >
              <label>{item == "English" ? "ENG" : "HIN"}</label>
              <input
                type="radio"
                className="radio"
                name="language"
                value={item == "English" ? "eng" : "hin"}
                id={item == "English" ? "eng" : "hin"}
                onChange={(e) => setLang(e)}
                checked={
                  (item == "English" && language == "eng") ||
                  (item == "Hindi" && language == "hin")
                    ? true
                    : false
                }
              />
            </div>
          );
        })
      ) : (
        <>
          <div
            className={` flex justify-between px-4  w-full mx-4 my-2 py-4 border border-[solid] rounded-md ${
              isDarkMode ? "border-white" : "border-black"
            } `}
          >
            <label>{"ENG"}</label>
            <input
              type="radio"
              className="radio"
              name="language"
              value={"eng"}
              id={"eng"}
              onChange={(e) => setLang(e)}
              checked={"eng" == language ? true : false}
            />
          </div>

          <div
            className={` flex justify-between px-4  w-full mx-4 my-2 py-4 border border-[solid] rounded-md ${
              isDarkMode ? "border-white" : "border-black"
            } `}
          >
            <label>{"HIN"}</label>
            <input
              type="radio"
              className="radio"
              name="language"
              value={"hin"}
              id={"hin"}
              onChange={(e) => setLang(e)}
              checked={"hin" == language ? true : false}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default ChooseLanguage;
