import { createSlice } from "@reduxjs/toolkit";

const ReportModalSlice = createSlice({
  name: "report",
  initialState: {
    isOpen: false,
  },
  reducers: {
    openModal: function (state, action) {
      state.isOpen = true;
    },
    closeModal: function (state, action) {
      state.isOpen = false;
    },
  },
});

export default ReportModalSlice.reducer;
export const { openModal, closeModal } = ReportModalSlice.actions;
