import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import tick from "../asset/images/tick.svg";
import { publish, subscribe, unsubscribe } from "../event";

const SubmissionSuccess = () => {
  const dispatch = useDispatch();
  const isFull = useSelector((state) => state.submitConfirmation.isFull);

  const fun = () => {
    // console.log("back");
    // var r = window.confirm("You pressed a Back button! Are you sure?!");
    //   if (r == true) {
    //     // Call Back button programmatically as per user confirmation.
    //     window.history.back();
    //     // Uncomment below line to redirect to the previous page instead.
    //     // window.location = document.referrer // Note: IE11 is not supporting this.
    //   } else {
    //     // Stay on the current page.
    //     window.history.pushState(null, null, window.location.pathname);
    //   }
    //   window.history.pushState(null, null, window.location.pathname);
  };
  useEffect(() => {
    // window.addEventListener("popstate", fun());
    // return () => {
    //     window.removeEventListener("popstate");
    // };

    // const handleCustomEvent = (event) => {
    //   console.log("Custom event received:", event.detail);
    // };
    // // Subscribe to the custom event
    // subscribe("btnGoBack", handleCustomEvent);
    // subscribe("btnViewResult", handleCustomEvent);
    // // Cleanup subscription on component unmount
    // return () => {
    //   unsubscribe("btnGoBack", handleCustomEvent);
    //   unsubscribe("btnViewResult", handleCustomEvent);
    // };
  }, []);

  const triggerEvent = (data) => {
    // publish(data, { message: "Hello from React!" });
  };

  const btnGoBack = (data) => {
    console.log("Go Back Clicked");
    window.Android.btnGoBack(data);
  };
  const btnViewResult = (data) => {
    console.log("View Result Clicked");
    window.Android.btnViewResult(data);
  };

  return (
    <div className="bg-[#111b21]  h-[100vh] flex justify-center items-center gap-2">
      <div className="test text-lg bg-opacity-45 p-4 text-center flex-col select-none flex justify-center items-center rounded-xl">
        <img src={tick} alt="" width={70} className="mb-4" />
        <p className="text-3xl ">Thank you</p>
        <span className="text-sm">
          {isFull ? "Test Submission succesfully." : "Test Paused succesfully."}
        </span>
        <div className="justify-center mt-10 mb-4">
          {isFull ? (
            <button
              className={`text-black border w-full bg-white  border-solid border-[#8696A0] rounded-md px-4 py-2 mb-1`}
              onClick={() => {
                btnViewResult("view-result");
                triggerEvent("btnViewResult");
              }}
            >
              View Result
            </button>
          ) : (
            ""
          )}
          <button
            className={`text-white border w-full bg-black border-solid border-[#8696A0] rounded-md px-4 py-2 mt-1`}
            onClick={() => {
              btnGoBack("go-back");
              triggerEvent("btnGoBack");
            }}
          >
            Go Back
          </button>
        </div>
      </div>
    </div>
  );
};

export default SubmissionSuccess;
