import { createSlice } from "@reduxjs/toolkit";

const TestAuthenticateSlice = createSlice({
  name: "testAuthenticate",
  initialState: {
    test: {},
  },
  reducers: {
    setTestDetail: function (state, action) {
      state.test = { ...action.payload };
    },
  },
});

export default TestAuthenticateSlice.reducer;
export const { setTestDetail } = TestAuthenticateSlice.actions;
