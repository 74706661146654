import { createSlice } from "@reduxjs/toolkit";
const TestConfigSlice = createSlice({
  name: "testConfig",
  initialState: {
    testId: 0,
    tk: "",
    auth: "",
  },
  reducers: {
    setTestId: function (state, action) {
      state.testId = action.payload;
    },
    setTk: function (state, action) {
      state.tk = action.payload;
    },
    setAuth: function (state, action) {
      state.auth = action.payload;
    },
  },
});

export default TestConfigSlice.reducer;
export const { setTestId, setTk, setAuth } = TestConfigSlice.actions;
