import React from "react";
import leftarr from "../asset/images/leftarr.svg";
import rightarr from "../asset/images/rightarr.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  setCurrentQuestion,
  setCurrentQuestionIndex,
} from "../redux/TestQuestionListSlice";
import { urls } from "../data/data";
import { apiRequestGet } from "../data/Api";
import { setQuestionDetail } from "../redux/QuestionDetailSlice";
import {
  clearCheckedOptions,
  setCurrentAnswer,
  setCurrentAnswerChangeFalse,
  setCurrentOption,
} from "../redux/AnswerSlice";
import { setLanguage } from "../redux/LanguageSlice";

const QuestionList = ({ isResult }) => {
  const dispatch = useDispatch();
  const testQuestionListData = useSelector(
    (state) => state.testQuestionList?.testQues
  );
  const currentTopic = useSelector(
    (state) => state.testQuestionList?.currentTopic
  );
  const currentQuestion = useSelector(
    (state) => state.testQuestionList?.currentQuestion
  );

  const testType = useSelector(
    (state) => state.testAuthenticate.test?.testType
  );
  const currentTopicIndex = useSelector(
    (state) => state.testQuestionList?.currentTopicIndex
  );
  const currentQuestionIndex = useSelector(
    (state) => state.testQuestionList?.currentQuestionIndex
  );
  const testId = useSelector((state) => state.testConfig.testId);
  const isDarkMode = useSelector((state) => state.darkMode.isDarkMode);

  let forResult = "";
  if (isResult)
    forResult = "result";
  const language = useSelector((state) => state.modal.language);
  const getQuestionDetails = (questId) => {
    fetch(
      `${urls.questionDetail}${questId}?${new URLSearchParams({
        testType,
        testId,
        for: forResult,
      })}`,
      apiRequestGet
    )
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        const quesLang = Object.keys(res.data?.ques).filter(
          (key) => res.data?.ques[key] !== ""
        );
        dispatch(
          setLanguage(quesLang.includes(language) ? language : quesLang?.[0])
        );
        dispatch(setQuestionDetail(res.data));
      })
      .catch((err) => {
        console.log(err);
      });
  };
  
  return (
    <div className=" sc flex gap-2 py-1 px-5 overflow-y-scroll  h-fit  select-none relative">
      <img
        src={leftarr}
        alt=""
        className={`select-none absolute left-5 top-3  ${
          !isDarkMode && "filter brightness-0"
        } `}
      />

      <div className="mx-5 flex overflow-x-auto w-full">
        <img
          src={leftarr}
          alt=""
          className={`select-none absolute left-5 top-3     ${
            !isDarkMode && "filter brightness-0"
          }`}
        />
        {testQuestionListData?.tpcQues?.[currentTopicIndex]?.quesList.map(
          (item, index) => {
            return (
              <div
                key={index}
                value={item.id}
                id={item.id}
                onClick={(e) => {
                  dispatch(setCurrentOption(""));
                  dispatch(setCurrentQuestion(e.target.getAttribute("value")));
                  dispatch(setCurrentQuestionIndex(index));
                  dispatch(clearCheckedOptions());
                  dispatch(setCurrentAnswer(""));
                  getQuestionDetails(e.target.getAttribute("value"));
                  dispatch(setCurrentAnswerChangeFalse());
                }}
                className={`${
                  currentQuestionIndex == index
                    ? " bg-[#111B21] text-white border border-solid border-[#8696A0] "
                    : "bg-[#8696A0] text-[#000000]"
                } cursor-pointer rounded-full inline-block whitespace-nowrap  !min-w-6 select-none m-1`}
              >
                {index + 1}
              </div>
            );
          }
        )}
        <img
          src={rightarr}
          alt=""
          className={`select-none absolute right-5 top-3   ${
            !isDarkMode && "filter brightness-0"
          } `}
        />
      </div>
    </div>
  );
};

export default QuestionList;
