import React, { useEffect } from "react";
import SubjectList from "./SubjectList";
import QuestionList from "./QuestionList";
import SideBar from "./SideBar";
import { useDispatch, useSelector } from "react-redux";
import Head from "./Head";
import { urls } from "../data/data";
import { apiRequestGet, setMyAuth } from "../data/Api";
import {
  setCurrentQuestion,
  setCurrentQuestionIndex,
  setCurrentTopic,
  setCurrentTopicIndex,
  setCurrentTopicLength,
  setQuestionListData,
  setTotalTopicLength,
} from "../redux/TestQuestionListSlice";
import Question from "./Question";
import { setQuestionDetail } from "../redux/QuestionDetailSlice";
import { setTimer } from "../redux/TimerSlice";
import { setAuth, setTestId } from "../redux/TestConfigSlice";
import { addAttemptedAnswer } from "../redux/AnswerSlice";

const TestSeries = ({ isResult }) => {
  const dispatch = useDispatch();

  const sideBar = useSelector((state) => state.sidebar.isOpen);
  const testType = useSelector(
    (state) => state.testAuthenticate.test?.testType
  );
  const currentTopicIndex = useSelector(
    (state) => state.testQuestionList?.currentTopicIndex
  );
  const currentQuestionIndex = useSelector(
    (state) => state.testQuestionList?.currentQuestionIndex
  );
  const testId = useSelector((state) => state.testConfig.testId);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const qTestId = parseInt(urlParams.get("testId"));
    const qAuth = urlParams.get("auth");
    if (qAuth != undefined && qAuth.length) {
      qTestId && dispatch(setTestId(qTestId));
      qAuth && dispatch(setAuth(qAuth));
      qAuth && setMyAuth(qAuth);
    }

    const timmer = setTimeout(() => {
      fetchResult(qTestId);
    }, [1000]);
    return () => {
      clearTimeout(timmer);
    };
  }, []);

  const fetchResult = (qTestId) => {
    fetch(
      (!isResult ? urls.testQuesList : urls.resultUrl) +
        (qTestId ? qTestId : testId),
      apiRequestGet
    )
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        dispatch(setTotalTopicLength(res.data?.tpcQues?.length));
        dispatch(setQuestionListData(res.data));
        dispatch(
          setCurrentTopic(res.data?.tpcQues?.[currentTopicIndex].topicName)
        );
        dispatch(addAttemptedAnswer(res.data.attemptedData));
        if (!isResult) {
          if (res.data.timeConsumed) {
            const mins =
              parseInt(res?.data?.durMins) -
              parseInt(res.data.timeConsumed.split(":")[1]);
            if (mins <= 60) {
              const timer = {
                hr: Math.floor(mins / 60),
                min: mins % 60,
                sec: 0,
              };
              dispatch(setTimer(timer));
            } else if (mins > 60) {
              const timer = {
                hr: Math.floor(mins / 60),
                min: mins % 60,
                sec: 0,
              };
              dispatch(setTimer(timer));
            }
          } else {
            if (res?.data?.durMins <= 60) {
              const timer = {
                hr: Math.floor(res?.data?.durMins / 60),
                min: res?.data?.durMins % 60,
                sec: 0,
              };
              dispatch(setTimer(timer));
            } else if (res?.data?.durMins > 60) {
              const timer = {
                hr: Math.floor(res?.data?.durMins / 60),
                min: res?.data?.durMins % 60,
                sec: 0,
              };
              dispatch(setTimer(timer));
            }
          }
        }

        if (res.data?.resumeSec) {
          let currentTopicQuestion = res.data?.resumeSec.split(",");
          getQuestionDetails(
            res.data?.tpcQues?.[currentTopicQuestion[0]].quesList?.[
              currentTopicQuestion[1]
            ].id
          );
          dispatch(setCurrentQuestionIndex(parseInt(currentTopicQuestion[1])));
          dispatch(setCurrentTopicIndex(parseInt(currentTopicQuestion[0])));
          dispatch(
            setCurrentQuestion(
              res.data?.tpcQues?.[currentTopicQuestion[0]].quesList?.[
                currentTopicQuestion[1]
              ].id
            )
          );
        } else {
          getQuestionDetails(
            res.data?.tpcQues?.[currentTopicIndex].quesList?.[
              currentQuestionIndex
            ].id
          );
          dispatch(
            setCurrentQuestion(
              res.data?.tpcQues?.[currentTopicIndex].quesList?.[
                currentQuestionIndex
              ].id
            )
          );
        }

        dispatch(
          setCurrentTopicLength(
            res.data?.tpcQues?.[currentTopicIndex].quesList?.length
          )
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getQuestionDetails = (questId) => {
    let forResult = "";
    if (isResult) forResult = "result";
    fetch(
      `${urls.questionDetail}${questId}?${new URLSearchParams({
        testType,
        testId,
        for: forResult,
      })}`,
      apiRequestGet
    )
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        dispatch(setQuestionDetail(res.data));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const isDarkMode = useSelector((state) => state.darkMode.isDarkMode);

  return (
    <div className={` ${isDarkMode ? "dark" : "light"} min-h-[100vh] h-full`}>
      <Head isResult={isResult} />
      <SubjectList isResult={isResult} />
      <QuestionList isResult={isResult} />
      {sideBar && <SideBar isResult={isResult} />}
      <Question isResult={isResult} />
    </div>
  );
};

export default TestSeries;
