import "./App.css";
import Main from "./components/Main";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <div className="App h-[100vh]">
      <ToastContainer  />
      <Main />
      {/* <TestSeries/> */}
    </div>
  );
}

export default App;
