export const instructionsList = [
  "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration.",
  "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration.",
  "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration.",
  "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration.",
  "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration.",
  "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration.",
  "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration.",
  "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration.",
];

export const subjectList = [
  "Maths",
  "RY Sir",
  "English",
  "Reasoning",
  "GK",
  "Science",
];

export const urls = {
  test: "https://elearn.crwilladmin.com/api/v6/mcqtest-validate/",
  testQuesList: "https://elearn.crwilladmin.com/api/v6/mcqtest-ques/",
  resultUrl: "https://elearn.crwilladmin.com/api/v6/mcqtest-resultview/",
  questionDetail: "https://elearn.crwilladmin.com/api/v6/mcqtest-ques/detail/",
  reportQuestion: "https://elearn.crwilladmin.com/api/v6/mcqtest-markreport",
  submitQues: "https://elearn.crwilladmin.com/api/v6/mcqtest-sbmt",
};
