import React from "react";
import { useDispatch, useSelector } from "react-redux";
import info from "../asset/images/info.svg";
import bookmark from "../asset/images/bookmark.svg";
import { openModal } from "../redux/ReportModalSlice";

const QuestionDetails = () => {
  const questDetail = useSelector((state) => state.questionDetail?.questDetail);
  const currentQuestionIndex = useSelector(
    (state) => state.testQuestionList?.currentQuestionIndex
  );
  const answers = useSelector((state) => state.answers?.answers);
  const currentQuestion = useSelector(
    (state) => state.testQuestionList?.currentQuestion
  );
  const reportedQuestions = useSelector(
    (state) => state.reportedQuestions?.reportedQuestions
  );
  const dispatch = useDispatch();
  
  return (
    <div className="flex justify-between  items-center border-y-2 border-solid border-[#5B5B5B]">
      <p className="text-left text-lg  my-1">Q {currentQuestionIndex + 1}</p>
      <div className="flex gap-2 my-1">
        <p className="text-[#949494] border border-solid border-[#949494] rounded-xl px-2 py-1  uppercase">
          {questDetail?.type}
        </p>
        <img
          src={info}
          className={`${
            reportedQuestions.hasOwnProperty(currentQuestion) && "reported"
          }`}
          onClick={() => {
            dispatch(openModal());
          }}
          alt=""
        />

        {answers?.[currentQuestion]?.isMarked == 1 && (
          <img src={bookmark} alt="" />
        )}
      </div>
    </div>
  );
};

export default QuestionDetails;
