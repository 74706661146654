import { store } from "../redux/store";

const headers = {
  "Content-Type": "application/json",
  Accept: "application/json",
  token: store.getState().testConfig.auth,
  usertype: 2,
  apptype: "web",
};

export function setMyAuth(auth) {
  headers.token = auth;
}

export const apiRequestGet = {
  method: "GET",
  headers: headers,
};

export const apiRequestPost = {
  method: "POST",
  headers: headers,
};
