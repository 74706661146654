import React from "react";
import Instructions from "./Instructions";
import TestSeries from "./TestSeries";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Loading from "./Loading";
import SubmissionSuccess from "./SubmissionSuccess";

const Container = () => {
  return (
    <div className="h-full ">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Loading />} />
          <Route path="/instructions" element={<Instructions />} />
          <Route path="/test" element={<TestSeries isResult={false} />} />
          <Route path="/submssionSuccess" element={<SubmissionSuccess />} />
          <Route path="/result" element={<TestSeries isResult={true} />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default Container;
